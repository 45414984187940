var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"cancel-variant":"outline-secondary","ok-title":_vm.$t('Сохранить'),"no-close-on-backdrop":"","cancel-title":_vm.$t('Отмена'),"centered":"","size":_vm.getSize,"title":_vm.title},on:{"ok":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[(_vm.parent)?_c('div',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('Добавляется в эту категорию'))+": "),_c('b',[_vm._v(_vm._s(_vm.parent.name_uz))])]):_vm._e(),(_vm.parent && _vm.parent.level == 1)?[(!_vm.noBrand)?_c('b-form-group',{attrs:{"label":_vm.$t('Brend'),"label-for":"brand_id"}},[_c('validation-provider',{attrs:{"name":"brand_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"brand_id","state":errors.length > 3 ? false : null,"options":_vm.brandsList.data,"reduce":function (option) { return option.id; },"placeholder":_vm.$t('Mahsulot brendini tanlang'),"label":"name"},model:{value:(_vm.form.brand_id),callback:function ($$v) {_vm.$set(_vm.form, "brand_id", $$v)},expression:"form.brand_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-checkbox',{staticClass:"mb-2",attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.noBrand),callback:function ($$v) {_vm.noBrand=$$v},expression:"noBrand"}},[_vm._v(" Brend mavjud emas ")]),(_vm.noBrand)?_c('b-form-group',{attrs:{"label":_vm.$t('Kategoriya nomi'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name_uz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 3 ? false : null,"name":"name","placeholder":"Kategoriya nomi"},model:{value:(_vm.form.name_uz),callback:function ($$v) {_vm.$set(_vm.form, "name_uz", $$v)},expression:"form.name_uz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()]:(_vm.parent && _vm.parent.level == 2)?[_c('b-form-group',{attrs:{"label":_vm.$t('Mahsulot nomi'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name_uz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 3 ? false : null,"name":"name","placeholder":"Mahsulot nomi"},on:{"input":_vm.changeName},model:{value:(_vm.form.name_uz),callback:function ($$v) {_vm.$set(_vm.form, "name_uz", $$v)},expression:"form.name_uz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]:[_c('b-form-group',{attrs:{"label":"Nomi (Faqat telefon modelini kiriting)","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name_uz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 3 ? false : null,"name":"name","placeholder":""},on:{"input":_vm.changeName},model:{value:(_vm.form.name_uz),callback:function ($$v) {_vm.$set(_vm.form, "name_uz", $$v)},expression:"form.name_uz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('span',{staticClass:"text-muted"},[_vm._v("Tavsiflarni tanlashingiz bilan mahsulot nomiga avtomatik qo'shiladi")])],(((_vm.parent && _vm.parent.level == 3)||_vm.is_adding_product))?_c('p',{staticClass:"link mb-2 mt-2",on:{"click":function($event){_vm.show_in_advance_payment_amount=!_vm.show_in_advance_payment_amount}}},[_vm._v("Telefon tavsifini kiritish (B/U, e-sim, Xotira, RAM)")]):_vm._e(),(((_vm.parent && _vm.parent.level == 3)||_vm.is_adding_product) && _vm.show_in_advance_payment_amount )?_c('div',{staticClass:"warning-block"},[(_vm.show_in_advance_payment_amount)?_c('b-form-group',{attrs:{"label":"Ishlatilgan telefon","label-for":"in_advance_payment_amount"}},[_c('b-form-checkbox',{attrs:{"value":1,"unchecked-value":0},on:{"change":_vm.setFeatureName},model:{value:(_vm.form.is_used),callback:function ($$v) {_vm.$set(_vm.form, "is_used", $$v)},expression:"form.is_used"}},[_vm._v(" B/U ")])],1):_vm._e(),_c('b-form-group',{attrs:{"label":"E-sim telefon","label-for":"in_advance_payment_amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"btn-radios-1","options":_vm.simOptions,"aria-describedby":ariaDescribedby,"button-variant":"outline-primary","size":"md","name":"radio-btn-outline","buttons":""},model:{value:(_vm.form.sim_type),callback:function ($$v) {_vm.$set(_vm.form, "sim_type", $$v)},expression:"form.sim_type"}})]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Telefon RAM","label-for":"product_memory_ram_id"}},[_c('v-select',{staticClass:"w-100",staticStyle:{"width":"100%"},attrs:{"id":"product_memory_ram_id","options":_vm.memories,"reduce":function (option) { return option.id; },"placeholder":"RAM GB","label":"name"},on:{"input":_vm.setFeatureName},model:{value:(_vm.form.product_memory_ram_id),callback:function ($$v) {_vm.$set(_vm.form, "product_memory_ram_id", $$v)},expression:"form.product_memory_ram_id"}})],1),_c('b-form-group',{attrs:{"label":"Telefon xotirasi","label-for":"product_memory_id"}},[_c('v-select',{staticClass:"w-100",staticStyle:{"width":"100%"},attrs:{"id":"product_memory_id","options":_vm.memories,"reduce":function (option) { return option.id; },"placeholder":"ROM GB","label":"name"},on:{"input":_vm.setFeatureName},model:{value:(_vm.form.product_memory_id),callback:function ($$v) {_vm.$set(_vm.form, "product_memory_id", $$v)},expression:"form.product_memory_id"}})],1),_c('b-form-group',{attrs:{"label":"Rangi","label-for":"product_color_id"}},[_c('v-select',{staticClass:"w-100",staticStyle:{"width":"100%"},attrs:{"id":"product_color_id","options":_vm.colors,"reduce":function (option) { return option.id; },"placeholder":"Rang","label":"name"},on:{"input":_vm.setFeatureName},model:{value:(_vm.form.product_color_id),callback:function ($$v) {_vm.$set(_vm.form, "product_color_id", $$v)},expression:"form.product_color_id"}})],1)],1):_vm._e()],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }