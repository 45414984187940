<template>
  <div>
    <save
      ref="saveForm"
      :tabid="tab_id"
      @onSuccess="getItems"
    />
    <div class="d-flex justify-content-between">
      <b-tabs align="left">
        <b-tab
          title="Xotira hajmlari"
          :active="tab_id == 0"
          @click="onChange(0)"
        />
        <b-tab
          title="Ranglar"
          :active="tab_id == 1"
          @click="onChange(1)"
        />
      </b-tabs>
    </div>
    <!--  -->
    <b-button
      v-can="'warehouses.product_categories-store'"
      variant="primary"
      class="mr-2"
      @click="selected ? $refs.saveForm.add(selected) : $refs.saveForm.visible = true"
    >
      <feather-icon icon="PlusIcon" />Qo'shish
    </b-button>
    <b-overlay :show="loading">
      <good-table-column-search
        :items="items.data"
        :columns="columns"
        :total="items.total"
        :page="page"
        :filter="filterModel"
        @getItems="getItems"
        @add="$refs.saveForm.visible = true"
        @edit="(item) => $refs.saveForm.edit(item)"
        @delete="destroy"
        @onPageChange="(p) => page = p"
      />
    </b-overlay>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import Save from './save.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch, Save,
  },
  data() {
    return {
      page: 1,
      loading: false,
      items: {},
      tab_id: 0,
      filterModel: {
        per_page: 50,
      },
      columns: [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Nomi'),
          field: 'name',
          showField: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Действия'),
          field: 'action',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
    }),
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    if (this.$route.query.tab_id) {
      this.tab_id = Number(this.$route.query.tab_id)
      this.getItems()
    } else {
      this.getItems()
    }
  },
  methods: {
    ...mapActions({
      getMemoriesAction: 'productFeatures/index',
      getColorsAction: 'productFeatures/indexColor',
      destroyMemory: 'productFeatures/destroy',
      destroyColor: 'productFeatures/destroyColor',
    }),
    openTab(id) {
      this.tableType = id
    },
    async getItems() {
      this.loading = true
      await this.methodAction({ ...this.filterModel, page: this.page }).then(res => {
        if (res.success) {
          this.items = res.data
        }
      }).finally(() => {
        this.loading = false
      })
    },
    methodAction(data) {
      if (this.tab_id == 1) {
        return this.getColorsAction(data)
      }
      return this.getMemoriesAction(data)
    },
    destroyAction(data) {
      if (this.tab_id == 1) {
        return this.destroyColor(data)
      }
      return this.destroyMemory(data)
    },
    onChange(id) {
      this.tab_id = id
      this.changeQuery('tab_id', id)
      this.getItems()
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id).then(res => {
              showToast('success', this.$t('Успешно удалено'))
              this.getItems()
            }).catch(() => {
              showToast('success', this.$t('Успешно удалено'))
            })
          }
        })
    },
  },
}
</script>

<style scoped></style>
