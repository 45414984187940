<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    size="lg"
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
      >
        <div class="row">
          <div class="col-6">
            <b-form-group
              :label="$t('Nomi')"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="form.name"
                  :state="errors.length > 0 ? false:null"
                  name="name"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver,
  },
  props: {
    tabid: {
      type: Number,
      default: () => 1,
    },
  },
  data() {
    return {
      form: {
        id: null,
        name: null,
      },
      loading: false,
      selected: null,
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    ...mapGetters({ treeNodes: 'productCategory/GET_TREE' }),
  },
  watch: {
    visible(newVal) {
      if (!newVal) setTimeout(() => { clearObject(this.form) }, 200)
    },
  },
  mounted() {
    this.getItems()
    this.title = 'Tovar qo`shish'
    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess')
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      this.form.id = item.id
      this.form.name = item.name
      this.visible = true
    },
    method(data) {
      if (this.form.id) return this.update(data)
      return this.store(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    async getItems() {
      this.selected = null
      this.loading = true
      this.loading = false
    },
    ...mapActions({
      store: 'resource/storeBrands', update: 'resource/updateBrands',
    }),
  },
}
</script>

<style scoped>

</style>
