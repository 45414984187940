<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :size="getSize"
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{ invalid }"
    >
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="save"
      >
        <div
          v-if="parent"
          class="mb-2"
        >
          <!-- Ushbu turga qo'shiladi -->
          {{ $t('Добавляется в эту категорию') }}: <b>{{ parent.name_uz }}</b>
        </div>
        <template v-if="parent && parent.level == 1">
          <b-form-group
            v-if="!noBrand"
            :label="$t('Brend')"
            label-for="brand_id"
          >
            <validation-provider
              #default="{ errors }"
              name="brand_id"
              rules="required"
            >
              <v-select
                id="brand_id"
                v-model="form.brand_id"
                :state="errors.length > 3 ? false : null"
                :options="brandsList.data"
                :reduce="(option) => option.id"
                :placeholder="$t('Mahsulot brendini tanlang')"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-checkbox
            v-model="noBrand"
            class="mb-2"
            :value="true"
            :unchecked-value="false"
          >
            Brend mavjud emas
          </b-form-checkbox>
          <b-form-group
            v-if="noBrand"
            :label="$t('Kategoriya nomi')"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="name_uz"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="form.name_uz"
                :state="errors.length > 3 ? false : null"
                name="name"
                placeholder="Kategoriya nomi"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </template>

        <template v-else-if="parent && parent.level == 2">
          <b-form-group
            :label="$t('Mahsulot nomi')"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="name_uz"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="form.name_uz"
                :state="errors.length > 3 ? false : null"
                name="name"
                @input="changeName"
                placeholder="Mahsulot nomi"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </template>
        <template v-else>
          <b-form-group
            label="Nomi (Faqat telefon modelini kiriting)"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="name_uz"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="form.name_uz"
                :state="errors.length > 3 ? false : null"
                name="name"
                placeholder=""
                @input="changeName"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <span class="text-muted">Tavsiflarni tanlashingiz bilan mahsulot nomiga avtomatik qo'shiladi</span>
        </template>
        <!--  -->
        <p
          v-if="((parent && parent.level == 3)||is_adding_product)"
          class="link mb-2 mt-2"
          @click="show_in_advance_payment_amount=!show_in_advance_payment_amount"
        >Telefon tavsifini kiritish (B/U, e-sim, Xotira, RAM)</p>
        <div
          v-if="((parent && parent.level == 3)||is_adding_product) && show_in_advance_payment_amount "
          class="warning-block"
        >

          <b-form-group
            v-if="show_in_advance_payment_amount"
            label="Ishlatilgan telefon"
            label-for="in_advance_payment_amount"
          >
            <b-form-checkbox
              v-model="form.is_used"
              :value="1"
              :unchecked-value="0"
              @change="setFeatureName"
            >
              B/U
            </b-form-checkbox>
          </b-form-group>
          <!--  -->
          <b-form-group
            v-slot="{ ariaDescribedby }"
            label="E-sim telefon"
            label-for="in_advance_payment_amount"
          >
            <b-form-radio-group
              id="btn-radios-1"
              v-model="form.sim_type"
              :options="simOptions"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-primary"
              size="md"
              name="radio-btn-outline"
              buttons
            />
          </b-form-group>
          <!--  -->
          <b-form-group
            label="Telefon RAM"
            label-for="product_memory_ram_id"
          >
            <v-select
              id="product_memory_ram_id"
              v-model="form.product_memory_ram_id"
              :options="memories"
              :reduce="(option) => option.id"
              class="w-100"
              style="width: 100%"
              placeholder="RAM GB"
              label="name"
              @input="setFeatureName"
            />
          </b-form-group>

          <b-form-group
            label="Telefon xotirasi"
            label-for="product_memory_id"
          >
            <v-select
              id="product_memory_id"
              v-model="form.product_memory_id"
              :options="memories"
              :reduce="(option) => option.id"
              class="w-100"
              style="width: 100%"
              placeholder="ROM GB"
              label="name"
              @input="setFeatureName"
            />
          </b-form-group>

          <b-form-group
            label="Rangi"
            label-for="product_color_id"
          >
            <v-select
              id="product_color_id"
              v-model="form.product_color_id"
              :options="colors"
              :reduce="(option) => option.id"
              class="w-100"
              style="width: 100%"
              placeholder="Rang"
              label="name"
              @input="setFeatureName"
            />
          </b-form-group>
        </div>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'
import vSelect from 'vue-select'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver, vSelect,
  },
  data() {
    return {
      form: {
        id: null,
        parent_id: null,
        name_uz: null,
        brand_id: null,
        product_memory_id: null,
        product_memory_ram_id: null,
        product_color_id: null,
        name_original: '',
        sim_type: null,
        is_used: null,
        product_category_ids: null,
        unit_of_measure_id: 1,
      },
      filter: {
        per_page: 9999,
        total: 0,
        page: 1,
        name_uz: null,
        category_id: null,
        parent0_category_id: null,
        parent1_category_id: null,
        parent2_category_id: null,
        warehouse_id: null,
        is_cost_price: null,
        category_name: '',
        suffix_name: '',
      },
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      noBrand: false,
      show_in_advance_payment_amount: true,
      products: {},
      warehouseProducts: [],
      colors: [],
      memories: [],
      simOptions: [
        { text: 'eSIM', value: '1' },
        { text: 'Single SIM', value: '2' },
        { text: 'Dual SIM', value: '3' },
      ],
      ids: null,
      parent: null,
      is_adding_product: false,
      title: '',
      visible: false,
      brandsList: {},
      loading: false,
      isEdit: false,
      required,
    }
  },
  computed: {
    ...mapGetters({ unit_of_measures: 'resource/UNIT_OF_MEASURES' }),
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('ID'),
          field: 'id',
          isAmount: true,
        },
        {
          label: this.$t('Nomi'),
          field: 'name_uz',
        },
      ]
    },
    getSize() {
      if (this.parent && this.parent.level == 2) {
        return 'lg'
      }
      return ''
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form)
          this.parent = null
        }, 200)
      }
    },
  },
  mounted() {
    this.title = this.parent_id ? this.$t('Добавить тип продукта') : this.$t('Добавить категория продукта')
    this.getColorsAction({ per_page: 999 }).then(res => {
      this.colors = res.data.data
    })
    this.getMemoriesAction({ per_page: 999 }).then(res => {
      this.memories = res.data.data
    })
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess')
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      this.is_adding_product = false
      this.parent = item
      this.form.id = item.id
      this.form.name_uz = item.name_uz
      this.form.name_original = item.name_uz
      this.form.unit_of_measure_id = item.unit_of_measure_id
      this.form.product_memory_id = item.product_memory_id
      this.form.product_memory_ram_id = item.product_memory_ram_id
      this.form.product_color_id = item.product_color_id
      this.form.sim_type = item.sim_type
      this.form.is_used = item.is_used
      this.visible = true
      this.isEdit = true
    },
    add(item) {
      this.form.parent_id = item.id
      this.parent = item
      this.isEdit = false
      this.visible = true
      if (item.level == 2) {
        this.is_adding_product = true
      }
      this.unitOfMeasures()
      this.getBrandsList()
    },
    method(data) {
      if (this.form.id) return this.update(data)
      return this.store(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    getBrandsList() {
      this.getBrands({ per_page: 9999 }).then(res => {
        this.brandsList = res.data
      })
    },
    getNameMemory(ram_id, mem_id) {
      const ram_obj = this.memories.find(el => el.id == ram_id)
      const mem_obj = this.memories.find(el => el.id == mem_id)
      return `${ram_obj.name}/${mem_obj.name}`
    },
    getNameColor(id) {
      const col_obj = this.colors.find(el => el.id == id)
      return col_obj.name
    },
    setFeatureName() {
      const ramObj = this.memories.find(el => el.id == this.form.product_memory_ram_id)
      const memObj = this.memories.find(el => el.id == this.form.product_memory_id)
      const colObj = this.colors.find(el => el.id == this.form.product_color_id)
      const isUsed = this.form.is_used
      const memName = (ramObj ? ramObj.name : '') + (ramObj && memObj ? '/' : '') + (memObj ? (`${memObj.name}`) : '')
      const memNameFull = memName ? `${memName.replaceAll(' GB', '')}GB` : ''

      this.form.name_uz = `${this.form.name_original} ${memNameFull}${colObj ? (` ${colObj.name}`) : ''}${isUsed ? (' B/U') : ''}`
    },
    changeName() {
      this.form.name_original = this.form.name_uz
    },
    ...mapActions({
      getCategories: 'productCategory/index',
      getMemoriesAction: 'productFeatures/index',
      getColorsAction: 'productFeatures/indexColor',
      store: 'productCategory/store',
      update: 'productCategory/update',
      unitOfMeasures: 'resource/unitOfMeasures',
      getBrands: 'resource/indexBrands',
    }),
  },
}
</script>

<style scoped></style>
